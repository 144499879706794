const Lodging = function ({ width, height, color }) {
  return (
    <svg
      width={width ? width : "53"}
      height={height ? height : "111"}
      viewBox="0 0 53 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.8624 38.2765L17.0171 42.0803C12.3941 43.3535 8.31579 46.1075 5.4073 49.9195C2.49837 53.7316 0.918994 58.3917 0.911235 63.1874V92.6602C0.914699 97.4421 2.8158 102.027 6.19743 105.408C9.57874 108.79 14.164 110.691 18.9455 110.694H34.0543C38.8362 110.691 43.4215 108.789 46.8023 105.408C50.1841 102.027 52.0851 97.4416 52.0885 92.6602V25.8942C52.0816 19.11 49.3835 12.6053 44.5861 7.80729C39.7887 3.01031 33.2844 0.311742 26.4992 0.304871H26.4196C12.3482 0.304871 0.910156 12.1685 0.910156 26.6522V28.3281C0.910156 30.7322 2.1925 32.954 4.27494 34.1564C6.35695 35.3587 8.92263 35.3587 11.0046 34.1564C13.0871 32.954 14.3694 30.7322 14.3694 28.3281V25.4023C14.508 21.2746 16.79 17.5184 20.3894 15.4936C23.989 13.4687 28.3842 13.4687 31.9837 15.4936C35.5833 17.5185 37.8652 21.2746 38.0037 25.4023V28.9001C38.0042 31.0293 37.3058 33.1001 36.0156 34.7942C34.725 36.4879 32.9145 37.7113 30.8614 38.2768L30.8624 38.2765ZM26.181 9.60168C21.9914 9.60515 17.9746 11.2711 15.0123 14.2337C12.0498 17.196 10.3837 21.2128 10.3803 25.4024V28.3282C10.3803 29.3071 9.85816 30.2115 9.01049 30.7011C8.1628 31.1904 7.11857 31.1904 6.2708 30.7011C5.4231 30.2115 4.90102 29.3071 4.90102 28.3282V26.6523C4.90102 14.3631 14.5301 4.33488 26.5002 4.29498C32.2276 4.29845 37.7199 6.57527 41.7697 10.625C45.8195 14.6752 48.0963 20.1666 48.0998 25.8945V92.6605C48.0963 96.3843 46.6156 99.9543 43.9825 102.587C41.3493 105.221 37.7788 106.702 34.0552 106.705H18.9464C15.2226 106.702 11.6522 105.22 9.01903 102.587C6.38585 99.9542 4.90523 96.3842 4.90179 92.6605V63.1877C4.90482 59.2627 6.19411 55.4471 8.57226 52.3243C10.95 49.2019 14.2858 46.9445 18.0689 45.8977L31.9142 42.2004C34.8193 41.3934 37.3788 39.6551 39.2002 37.2523C41.0216 34.8494 42.0035 31.9154 41.9957 28.9004V25.4026C41.9888 21.2118 40.3198 17.1949 37.3555 14.2329C34.3907 11.2706 30.3725 9.60512 26.1823 9.60168H26.181Z"
        fill="white"
      />
      <path
        d="M35.81 56.4177C32.4209 53.4386 27.7446 52.3982 23.4122 53.6589C19.0801 54.9201 15.6924 58.3074 14.4317 62.6399C13.1705 66.9723 14.2109 71.6485 17.19 75.0377C20.579 78.0163 25.2553 79.0567 29.5878 77.796C33.9198 76.5348 37.3076 73.1475 38.5683 68.815C39.8295 64.483 38.7891 59.8064 35.81 56.4172V56.4177ZM17.19 65.7277C17.1895 63.5153 17.9857 61.377 19.4335 59.7041C20.8808 58.0312 22.8823 56.9355 25.0712 56.6176C27.2606 56.2998 29.4911 56.7813 31.3545 57.9736L18.6397 70.6751H18.6392C17.7331 69.1949 17.2629 67.489 17.2829 65.7538L17.19 65.7277ZM26.5 74.971C24.7457 74.9727 23.0282 74.4696 21.5523 73.5215L34.2671 60.82C35.462 62.683 35.9443 64.9147 35.6252 67.105C35.3066 69.2953 34.2078 71.2967 32.5314 72.7419C30.8551 74.187 28.7133 74.9785 26.5001 74.9711L26.5 74.971Z"
        fill="white"
      />
      <path
        d="M37.7119 84.6669H15.2881C14.5755 84.6669 13.917 85.047 13.5602 85.6644C13.2039 86.2817 13.2039 87.042 13.5602 87.6594C13.9169 88.2767 14.5755 88.6569 15.2881 88.6569H37.7119C38.4245 88.6569 39.083 88.2767 39.4398 87.6594C39.7961 87.042 39.7961 86.2817 39.4398 85.6644C39.083 85.047 38.4245 84.6669 37.7119 84.6669Z"
        fill="white"
      />
      <path
        d="M33.15 94.3493H19.85C19.1374 94.3493 18.4784 94.7294 18.1221 95.3468C17.7658 95.9642 17.7658 96.7245 18.1221 97.3418C18.4784 97.9592 19.1373 98.3393 19.85 98.3393H33.15C33.8626 98.3393 34.5215 97.9592 34.8779 97.3418C35.2342 96.7244 35.2342 95.9641 34.8779 95.3468C34.5216 94.7294 33.8626 94.3493 33.15 94.3493Z"
        fill="white"
      />
    </svg>
  );
};

export default Lodging;
