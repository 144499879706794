import tw, { styled } from "twin.macro";

export const StyledQrCode = styled.div(({ theme }) => [
  tw`flex justify-center items-center w-[112.375rem] h-[119.125rem] bg-[#1E2334] mb-[15.054rem] mt-[5.125rem]`,
  `border: 1px solid #000000;`,
  `border-radius: 48px;`,
  `box-sizing: border-box;`,
]);

export const QrContainer = tw.div`flex justify-center items-center flex-col`;
