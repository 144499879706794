const Info = function ({ width, height, color }) {
  return (
    <svg
      width="94"
      height="95"
      viewBox="0 0 94 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M93.3333 47.5307C93.3333 73.2654 72.4013 94.1973 46.6667 94.1973C20.932 94.1973 0 73.2654 0 47.5307C0 21.796 20.932 0.864014 46.6667 0.864014C72.4013 0.864014 93.3333 21.8013 93.3333 47.5307ZM85.3333 47.5307C85.3333 26.1973 68 8.86402 46.6667 8.86402C25.3333 8.86402 8 26.1973 8 47.5307C8 68.864 25.3333 86.1973 46.6667 86.1973C68 86.3328 85.3333 68.864 85.3333 47.5307Z"
        fill="white"
      />
      <path
        d="M46.667 40.7346C44.4014 40.7346 42.667 42.469 42.667 44.7346V65.136C42.667 67.4016 44.4014 69.136 46.667 69.136C48.9326 69.136 50.667 67.4016 50.667 65.136V44.7346C50.667 42.469 48.9326 40.7346 46.667 40.7346Z"
        fill="white"
      />
      <path
        d="M46.667 26.068C45.5993 26.068 44.5316 26.469 43.8649 27.2659C43.068 27.9326 42.667 29.0003 42.667 30.068C42.667 30.3337 42.667 30.5993 42.8024 30.8701C42.8024 31.1358 42.9378 31.4014 43.068 31.6722C43.2034 31.9378 43.3337 32.0733 43.4691 32.3389C43.6045 32.6045 43.7347 32.7399 44.0003 33.0055C45.4691 34.4743 48.1358 34.4743 49.5993 33.0055C49.7347 32.8701 50.0003 32.6045 50.1305 32.3389C50.2659 32.0733 50.3961 31.9378 50.5316 31.6722C50.667 31.4066 50.667 31.141 50.7972 30.8701C50.7972 30.6045 50.9326 30.3389 50.9326 30.068C50.9326 29.0003 50.5316 27.9326 49.7347 27.2659C48.8024 26.469 47.7347 26.068 46.6669 26.068H46.667Z"
        fill="white"
      />
    </svg>
  );
};

export default Info;
