import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import {
  CategoriesContainer,
  CategoriesCardIcon,
  CategoriesCardImage,
  CategoriesCardTitle,
} from "./Categories.styles";

const Categories = function ({ bgColor, bgImage, title, Icon, onClick }) {
  const { t } = useTranslation();
  return (
    <CategoriesContainer bgColor={bgColor} bgImage={bgImage} onClick={onClick}>
      {bgImage && <CategoriesCardImage src={bgImage} alt="bgImage" />}
      <CategoriesCardIcon>
        {Icon && <Icon color="#fff" />}
      </CategoriesCardIcon>
      <CategoriesCardTitle bgColor={bgColor}>{t(title)}</CategoriesCardTitle>
    </CategoriesContainer>
  );
};

Categories.propTypes = {
  image: PropTypes.string,
};

export default Categories;
