import Emoji from "../Emoji";
import { Close, NotBad, Success, Terrible, Vector } from "../Icons";
import Good from "../Icons/Good";
import {
  CloseIcon,
  EmojiSection,
  ModalContainer,
  SecondarySection,
  Text,
  Title,
  TitleContainer,
} from "./Modal.styles";
import { useMutation } from "react-query";
import { createReview } from "../../services/reviews";
import { PLACE_ID } from "../../config/constants";
import { useTranslation } from "react-i18next";
const REVIEW_KPIs = [
  "staff",
  "facilities",
  "cleanliness",
  "comfort",
  "valueForMoney",
  "location",
  "wifi",
];
const Modal = function ({ title, variant = "secondary", text, showModal }) {
  const { t } = useTranslation();
  const onNewMutation = useMutation((data) => createReview(data), {
    onSuccess: () => {
      showModal.setModalv2(true);
    },
    onError: () => {
      console.log("error");
    },
  });

  const changeReview = (number) => {
    return REVIEW_KPIs.reduce((a, v) => ({ ...a, [v]: number }), {});
  };

  //handle revie submit
  const handleSubmit = (review) => {
    onNewMutation.mutate({
      ...review,
      place: PLACE_ID,
    });
  };

  //handle all Reviews by title
  const handleReview = (state) => {
    let review = {};
    if (state === "exellent") review = changeReview(5);
    else if (state === "good") review = changeReview(3);
    else if (state === "terrible") review = changeReview(1);

    handleSubmit(review);
  };
  return (
    <ModalContainer>
      <TitleContainer variant={variant}>
        <Title variant={variant}>{t(title)}</Title>
        <CloseIcon
          onClick={() => {
            showModal.setShowModal(false);
            showModal.setModalv2(false);
          }}
        >
          <Close />
        </CloseIcon>
      </TitleContainer>
      <EmojiSection variant={variant}>
        <Emoji
          Icon={Good}
          bgColor="#52ACB5"
          title={t("veryGood")}
          onClick={() => {
            handleReview("exellent");
            showModal.setModalv2(true);
          }}
        />
        <Emoji
          Icon={NotBad}
          bgColor="#F0B371"
          title={t("notBad")}
          onClick={() => {
            handleReview("good");
            showModal.setModalv2(true);
          }}
        />
        <Emoji
          Icon={Terrible}
          bgColor="#E55675"
          title={t("terrible")}
          onClick={() => {
            handleReview("terrible");
            showModal.setModalv2(true);
          }}
        />
      </EmojiSection>
      <SecondarySection variant={variant}>
        <Success />
        <Vector className="absolute -top-[12.88rem] right-[19.963rem] " />
        <Vector
          className="absolute -top-[6.088rem] left-[13.25rem]"
          width="236"
          height="236"
        />
        <Vector
          className="absolute top-[16.088rem] left-[10.25rem]"
          width="136"
          height="136"
        />
        <Vector
          className="absolute top-[9.088rem] right-[13.25rem]"
          width="226"
          height="226"
        />
        <Text>{t(text)}</Text>
      </SecondarySection>
    </ModalContainer>
  );
};

Modal.propTypes = {};

export default Modal;
