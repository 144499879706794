const Plus = function ({ width, height, color }) {
  return (
    <svg
      width="53"
      height="52"
      viewBox="0 0 53 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5 52C25.0371 52 23.8483 50.8335 23.8483 49.3984V28.6016H2.65165C1.1888 28.6016 0 27.4352 0 26C0 24.5647 1.18888 23.3984 2.65165 23.3984H23.8483V2.60162C23.8483 1.16637 25.0372 0 26.5 0C27.9628 0 29.1516 1.16645 29.1516 2.60162V23.3984H50.3483C51.8112 23.3984 53 24.5648 53 26C53 27.4353 51.8111 28.6016 50.3483 28.6016H29.1516V49.3984C29.1516 50.8336 27.9628 52 26.5 52Z"
        fill="#477C7D"
      />
    </svg>
  );
};

export default Plus;
