import { ButtonSection, StyledIcon } from "./Button_v1.styles";

const ButtonV1 = ({ label, handleClick, Icon }) => {
  return (
    <ButtonSection onClick={handleClick}>
      <StyledIcon label={label}>{Icon && <Icon />}</StyledIcon>

      {label}
    </ButtonSection>
  );
};

export default ButtonV1;
