import { useEffect, useState } from "react";
import CategoriesGrid from "../../components/CategoriesGrid";
import FeedBackCard from "../../components/FeedBackCard";
import Header from "../../components/Header";
import { Map, Plan, Shapes2, Shapes3 } from "../../components/Icons";
import {
  fetchData,
  formatDate,
  getLocation,
  mainCategories,
} from "../services";
import { CategoriesSection } from "./home.styles";
import useData from "../../hooks/useData";
import Swiperv1 from "../../components/Swiper";
import ModalBlur from "../../components/ModalBlur";
import Modal from "../../components/Modal";
import Image1 from "../../assets/images/image1.jpg";
import Image2 from "../../assets/images/image2.jpg";
import Image3 from "../../assets/images/image3.jpg";
import { getImage } from "../../helpers/utils";
import { getIcon } from "../servicesIcons";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";

const noImage =
  "https://concierge.fra1.digitaloceanspaces.com/86847967-8f46-4405-b7aa-15e66b638260.png";

const Home = function () {
  // const [location, setLocation] = useState();
  // const [weather, setWeather] = useState();
  // const [weatherIcon, setWeatherIcon] = useState();
  const [locales, setLocales] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalv2, setModalv2] = useState(false);
  const [reformattedSwiper, setReformattedSwiper] = useState({});
  const [reformattedHeader, setReformattedHeader] = useState({});
  const { fetchedData, setFetchedData } = useData();
  const [reformattedData, setReformattedData] = useState();
  const [LoadheaderContent, setLoadHeaderContent] = useState(false);
  const navigate = useNavigate();

  //Fetch Data
  useEffect(() => {
    fetchData().then((result) => {
      setFetchedData(result);
    });
    // eslint-disable-next-line
  }, []);

  //Get Categories And Location
  useEffect(() => {
    mainCategories(fetchedData).then(function (result) {
      handleReformattedData(result);
      getLocation(fetchedData).then(function (res) {
        handleReformattedHeader(res);
        handleReformattedSwiper();
        // setLocation(res?.loc);
        setLocales(res?.locales);
      });
    });
    // eslint-disable-next-line
  }, [fetchedData, LoadheaderContent]);

  // //Get Weather and WeatherIcon
  // useEffect(() => {
  //   location &&
  //     getweather(location).then(function (result) {
  //       setWeather(Math.round(parseFloat(result.main.temp)));
  //       setWeatherIcon(result.weather[0].icon);
  //     });
  // }, [location]);

  //Reformatte Categories
  const handleReformattedData = async (categories) => {
    setReformattedData(
      await Promise.all(
        categories?.map((data) => {
          return {
            bgColor: data.bgColor ? data.bgColor : "#386F71",
            bgImage: getImage(data.media) || noImage,
            title: data.dictionary.name[i18next.language] || data.name,
            Icon: getIcon(data.name),
            onClick: () => navigate(`/category/${data._id}`),
          };
        })
      )
    );
    if (reformattedData) {
      setReformattedData((current) => [
        ...current,
        {
          bgColor: "#3C5C43",
          bgImage:
            "https://images.unsplash.com/photo-1507099985932-87a4520ed1d5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80",
          title: "plan",
          Icon: Plan,
          onClick: () => navigate("/plan"),
        },
        {
          bgColor: "#494588",
          bgImage:
            "https://images.unsplash.com/photo-1496950866446-3253e1470e8e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80",
          title: "map",
          Icon: Map,
          onClick: () => navigate("/documentation"),
        },
      ]);
    }
  };

  //Reformatte Language
  const reformattedLanguge = locales?.map((data) => {
    return {
      language: data,
    };
  });

  //Reformatte Swiper
  const handleReformattedSwiper = () => {
    setReformattedSwiper({
      items: [
        {
          src: Image1,
        },
        {
          src: Image2,
        },
        {
          src: Image3,
        },
      ],
    });
  };

  //Reformatte Header
  const handleReformattedHeader = (data) => {
    setReformattedHeader({
      variant: "primary",
      day: formatDate(i18next.language),
      logo: data?.logo,
      // WeatherIcon: weatherIcon,
      // weather: weather && weather + "  °C",
      city: data?.city,
      location: data?.loc,
    });
  };

  return (
    <>
      {showModal && (
        <ModalBlur>
          <Modal
            text="feedbackSent"
            variant={modalv2 ? "secondary" : "primary"}
            title="experience2"
            showModal={{ setShowModal, setModalv2 }}
          />
        </ModalBlur>
      )}
      {reformattedHeader && <Header {...reformattedHeader} />}
      {reformattedLanguge && (
        <Swiperv1
          languages={reformattedLanguge}
          {...reformattedSwiper}
          load={{ LoadheaderContent, setLoadHeaderContent }}
        />
      )}
      <CategoriesSection>
        <Shapes2 className="absolute right-0 h-full" />
        <Shapes3 className="absolute w-full h-[99rem] -left-[30.868rem] -top-[0.002rem]" />
        {reformattedData && <CategoriesGrid items={reformattedData} />}
        <FeedBackCard title="experience" onClick={() => setShowModal(true)} />
      </CategoriesSection>
    </>
  );
};

export default Home;
