const Bar = function ({ width, height, color }) {
  return (
    <svg
      width={width ? width : "88"}
      height={height ? height : "84"}
      viewBox="0 0 88 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.6074 0.670364C57.6326 0.670364 49.2094 8.04264 47.6577 17.6491H34.5796L17.9548 0.893768C17.2899 0.223628 16.8465 0 15.9597 0H2.88153C1.3298 0 0 1.11702 0 2.90417C0 4.4681 1.55174 5.80834 2.88153 5.80834H14.8514L26.5995 17.6488H18.3979C15.7378 17.6488 14.408 20.3298 16.4027 22.5638L40.1207 46.6915V78.1917H30.5892C29.0374 78.1917 27.7076 79.7556 27.7076 81.0958C27.7076 82.883 29.2594 84 30.5892 84H56.0805C57.8537 84 58.962 82.883 58.962 81.0958C58.962 79.5319 57.8537 78.1917 56.0805 78.1917L46.1056 78.192V46.6919L56.0805 37.0855C59.6269 39.7665 63.6172 40.8835 68.0503 40.8835C79.1335 40.8835 88 31.7241 88 20.777C87.5566 9.60678 78.6901 0.670578 67.607 0.670578L67.6074 0.670364ZM53.6426 30.83C53.4208 31.0533 53.4208 31.0533 53.6426 30.83L43.2245 41.3301L25.713 23.6811H60.7359L53.6426 30.83ZM67.6074 35.0745C64.7259 35.0745 62.0658 34.1808 60.0707 32.6173L70.0455 22.5641C72.0407 20.7769 71.1539 17.649 68.0504 17.649H54.3073C55.859 11.1701 61.4006 6.4788 68.0504 6.4788C76.0301 6.4788 82.0152 12.9577 82.0152 20.5533C81.7937 28.5961 75.5871 35.0746 67.6071 35.0746L67.6074 35.0745Z"
        fill="white"
      />
    </svg>
  );
};

export default Bar;
