const Eye = function ({ width, height, color }) {
  return (
    <svg
      width={width} // added size here
      height={height} // added size here
      fill={color} // added color here
      viewBox="0 0 98 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.9998 0.800001C23.6259 0.800001 3.9668 27.84 3.13376 28.9861L0.952148 32.0004L3.13781 35.0148C3.96657 36.1604 23.6248 63.2008 48.9997 63.2008C74.3747 63.2008 94.0328 36.1608 94.8658 35.0148L97.0515 32.0004L94.8658 28.9861C94.033 27.8404 74.3747 0.800001 48.9997 0.800001H48.9998ZM48.9998 52.8C33.3998 52.8 19.4659 38.447 14.0538 32C19.4609 25.5528 33.2938 11.2 48.9998 11.2C64.5998 11.2 78.5338 25.553 83.9459 32C78.5347 38.4472 64.7059 52.8 48.9998 52.8Z"
        fill="#0E1222"
      />
      <path
        d="M48.9994 16.4C40.3666 16.4 33.3994 23.3672 33.3994 32C33.3994 40.6328 40.3666 47.6 48.9994 47.6C57.6322 47.6 64.5994 40.6328 64.5994 32C64.5994 23.3672 57.6322 16.4 48.9994 16.4ZM48.9994 37.2C46.0866 37.2 43.7994 34.9128 43.7994 32C43.7994 29.0872 46.0866 26.8 48.9994 26.8C51.9122 26.8 54.1994 29.0872 54.1994 32C54.1994 34.9128 51.9122 37.2 48.9994 37.2Z"
        fill="#0E1222"
      />
    </svg>
  );
};

export default Eye;
