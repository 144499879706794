import Cardv1 from "../Cards/Card_v1";
import { Cardv1MapSection } from "./Card_v1Map.styles";

const Cardv1Map = ({ items }) => {
  return (
    <Cardv1MapSection>
      {items.length > 0 &&
        items.map((item, index) => <Cardv1 key={index} {...item} />)}
    </Cardv1MapSection>
  );
};

export default Cardv1Map;
