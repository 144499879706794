import tw, { styled } from "twin.macro";

export const PdfModelContainer = tw.div`w-[102.5rem]`;

export const Title = styled.div(({ theme }) => [
  tw`font-semibold text-[6.25rem] leading-[7.688rem] text-white w-[65rem]`,
  `font-family: ${theme.fontFamily.secondary};`,
]);

export const TitleContainer = styled.div(() => [
  tw`flex justify-between items-center mb-[4.063rem]`,
]);

export const CloseIcon = tw.div`cursor-pointer`;

export const QrcodeBtn = styled.button(() => [
  tw`flex justify-center items-center w-[45.744rem] font-semibold text-[3.25rem] leading-[4rem] py-4 border-8 bg-[#0E1222] border-white text-white rounded-full gap-4`,
  `box-shadow: 2.6px 5.2px 31.2px rgba(0, 0, 0, 0.45);`,
]);

export const ElementRight = tw.div`flex gap-[2.5rem]`;

export const Buttonfield = tw.div`flex justify-between items-center mt-[3.938rem]`;
export const Pages = tw.div`flex items-end text-white mr-[0.75rem]`;
export const CurrentPage = tw.label`font-semibold text-[7.5rem] leading-[9.225rem]`;
export const TotalPages = tw.label`font-semibold text-[5rem] leading-[6.15rem]`;

export const PDFDocumentWrapper = styled.div(() => [
  tw`h-[119.313rem] w-[102.5rem]`,
  `
    canvas {
      width: 1640px !important;
      height: 1909px !important;
      border-radius: 32px !important;
      }
  `,
]);
