import tw, { styled } from "twin.macro";


export const StyledMapMarker = tw.div`flex flex-col items-center w-fit`;

export const MapMarkerContainer = styled.div(({ theme, currentPos }) => [
    tw`w-[12rem] h-[12rem] rounded-full font-semibold text-[2.5rem] leading-[3.075rem] 
     flex justify-center items-center`,
    `font-family: ${theme.fontFamily.secondary};`,
    currentPos ? `background: #477C7D;` : `background: #E8E8E8;`,
    !currentPos ? `color: #477C7D;` : `color: #E8E8E8;`,
    `border: 21px solid #477C7D;`,
    `box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.35);`,
  ]);