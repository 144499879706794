const Map = function ({ width, height, color }) {
  return (
    <svg
      width={width ? width : "107"}
      height={height ? height : "108"}
      viewBox="0 0 107 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.51665 45.5893H49.8059C51.0312 45.5893 52.0226 44.5961 52.0226 43.3726V4.95C52.0226 3.72651 51.0311 2.73334 49.8059 2.73334C48.5807 2.73334 47.5892 3.7265 47.5892 4.95V41.1559H2.51665C1.29139 41.1559 0.299988 42.1487 0.299988 43.3726C0.299988 44.5961 1.29143 45.5893 2.51665 45.5893Z"
        fill="#FFF7F7"
      />
      <path
        d="M58.6726 52.9774H104.483C105.709 52.9774 106.7 51.9842 106.7 50.7607C106.7 49.5373 105.709 48.5441 104.483 48.5441H60.8893V4.95C60.8893 3.72651 59.8978 2.73334 58.6726 2.73334C57.4473 2.73334 56.4559 3.7265 56.4559 4.95V50.7607C56.4559 51.9847 57.4474 52.9774 58.6726 52.9774Z"
        fill="#FFF7F7"
      />
      <path
        d="M104.483 57.4107H58.6726C57.4473 57.4107 56.4559 58.4039 56.4559 59.6274V68.423C55.6299 68.3165 54.7947 68.2429 53.941 68.2429C53.2938 68.2429 52.6539 68.2784 52.0226 68.3403V52.2385C52.0226 51.015 51.0311 50.0218 49.8059 50.0218H2.51665C1.29139 50.0218 0.299988 51.0145 0.299988 52.2385C0.299988 53.4619 1.29143 54.4551 2.51665 54.4551H47.5892V69.3212C39.9093 71.9665 34.3679 79.2473 34.3679 87.8137C34.3679 98.6075 43.1476 107.387 53.9411 107.387C64.7348 107.387 73.5143 98.6072 73.5143 87.8137C73.5143 79.4718 68.2579 72.3503 60.8915 69.5395L60.891 61.8432H104.485C105.71 61.8432 106.702 60.85 106.702 59.6265C106.702 58.403 105.709 57.4098 104.484 57.4098L104.483 57.4107ZM69.0787 87.8145C69.0787 96.1625 62.2867 102.954 53.9389 102.954C45.5909 102.954 38.7991 96.1624 38.7991 87.8145C38.7991 79.4678 45.591 72.6758 53.9389 72.6758C62.2873 72.6758 69.0787 79.4678 69.0787 87.8145Z"
        fill="#FFF7F7"
      />
      <path
        d="M51.8186 78.3649L43.6524 92.5084C43.1853 93.3167 43.2758 94.3319 43.8771 95.0459C44.4785 95.7581 45.463 96.0196 46.3406 95.6962L53.579 93.0184L61.1629 95.7048C61.4062 95.7918 61.656 95.8334 61.9028 95.8334C62.5501 95.8334 63.1783 95.5511 63.6082 95.0338C64.2022 94.3216 64.2879 93.312 63.8238 92.5084L55.6576 78.365C55.2615 77.6792 54.5302 77.2567 53.738 77.2567C52.9461 77.2567 52.2131 77.6792 51.8188 78.365L51.8186 78.3649ZM56.9789 89.5203L54.3025 88.5717C54.0631 88.486 53.8137 88.4444 53.5622 88.4444C53.302 88.4444 53.0405 88.4903 52.7924 88.5817L50.5594 89.409L53.7349 83.9059L56.9789 89.5203Z"
        fill="#FFF7F7"
      />
      <path
        d="M74.8299 36.6228C75.817 37.3246 76.6743 38.2749 77.3068 39.3716L80.382 44.702C81.1032 45.9507 82.3947 46.697 83.8369 46.697C85.2807 46.697 86.5722 45.9506 87.2918 44.702L90.2916 39.5076C90.9596 38.3534 91.7839 37.4329 92.7446 36.7722C97.3702 33.5978 99.8912 28.3519 99.4891 22.7363C98.9349 14.9866 92.6663 8.77239 84.909 8.28251C80.5406 8.00629 76.3778 9.49735 73.195 12.4855C70.0592 15.4292 68.2607 19.585 68.2607 23.8847C68.2611 28.9359 70.7172 33.6974 74.8298 36.6228H74.8299ZM76.2309 15.7174C78.3294 13.7476 81.0262 12.6851 83.8918 12.6851C84.1373 12.6851 84.3841 12.6925 84.6309 12.709C90.1842 13.0592 94.6708 17.5073 95.0683 23.0562C95.3566 27.083 93.5509 30.8457 90.2345 33.12C88.7257 34.1574 87.452 35.5613 86.4514 37.2931L83.8355 41.8195L81.1444 37.1559C80.1824 35.4891 78.9216 34.0955 77.3969 33.0092C74.4503 30.915 72.6917 27.503 72.6917 23.8854C72.6947 20.7578 73.9506 17.8571 76.231 15.7175L76.2309 15.7174Z"
        fill="#FFF7F7"
      />
      <path
        d="M83.7941 32.2893C88.2763 32.2893 91.9217 28.6422 91.9217 24.1616C91.9221 19.6807 88.2763 16.0336 83.7941 16.0336C79.3122 16.0336 75.6664 19.6807 75.6664 24.1612C75.6664 28.6422 79.3123 32.2893 83.7941 32.2893ZM83.7941 20.4667C85.8306 20.4667 87.4884 22.1231 87.4884 24.161C87.4888 26.1989 85.8306 27.8557 83.7941 27.8557C81.758 27.8557 80.0998 26.1989 80.0998 24.161C80.0998 22.1231 81.758 20.4667 83.7941 20.4667Z"
        fill="#FFF7F7"
      />
      <path
        d="M15.7191 29.2347C16.7062 29.9365 17.5635 30.8868 18.196 31.9835L21.2712 37.3139C21.9925 38.5625 23.2839 39.3089 24.7261 39.3089C26.1699 39.3089 27.4615 38.5625 28.181 37.3139L31.1808 32.1195C31.8488 30.9653 32.6732 30.0448 33.6339 29.3841C38.2594 26.2097 40.7804 20.9638 40.3783 15.3482C39.8241 7.59848 33.5555 1.38427 25.7982 0.894391C21.4255 0.621209 17.2653 2.10923 14.0842 5.09741C10.9484 8.04103 9.1499 12.1968 9.1499 16.4966C9.1499 21.5477 11.606 26.3092 15.719 29.2347H15.7191ZM17.1201 8.32931C19.2187 6.35947 21.9155 5.29702 24.7811 5.29702C25.0265 5.29702 25.2733 5.30438 25.5201 5.32083C31.0734 5.67109 35.56 10.1191 35.9575 15.6681C36.2458 19.6949 34.4401 23.4576 31.1237 25.7319C29.6149 26.7692 28.3412 28.1732 27.3406 29.905L24.7248 34.4314L22.0336 29.7678C21.0716 28.101 19.8109 26.7073 18.2861 25.6211C15.3395 23.5269 13.5809 20.1149 13.5809 16.4973C13.5835 13.3701 14.8399 10.4689 17.1203 8.32941L17.1201 8.32931Z"
        fill="#FFF7F7"
      />
      <path
        d="M24.6833 24.9C29.1655 24.9 32.8109 21.2529 32.8109 16.7724C32.8109 12.2914 29.1655 8.64429 24.6833 8.64429C20.2011 8.64429 16.5557 12.2914 16.5557 16.7724C16.5557 21.2529 20.2011 24.9 24.6833 24.9ZM24.6833 13.0774C26.7199 13.0774 28.3776 14.7338 28.3776 16.7717C28.3776 18.81 26.7199 20.4664 24.6833 20.4664C22.6467 20.4664 20.989 18.81 20.989 16.7721C20.989 14.7342 22.6467 13.0774 24.6833 13.0774Z"
        fill="#FFF7F7"
      />
    </svg>
  );
};

export default Map;
