import tw, { styled } from "twin.macro";

export const ButtonSection = styled.button(({ theme }) => [
  tw`flex justify-center items-center rounded-full font-medium text-[4.375rem] leading-[5.381rem] px-[3.375rem] py-[2.125rem]`,
  `font-family: ${theme.fontFamily.secondary};`,
  `color: ${theme.colors.text.secondary};`,
  `background-color: ${theme.colors.bg.primary};`,
  `border-style: solid;`,
  `border-width: 6px;`,
  `border-color: ${theme.colors.text.secondary};`,
]);

export const StyledIcon = styled.span(({ label }) => [
  label && tw`mr-[2.313rem]`,
]);