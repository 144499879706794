import Cardv2 from "../Cards/Card_v2";
import { Cardv2GridSection } from "./Card_v2Grid.styles";

const Cardv2Grid = ({ items }) => {
  return (
    <Cardv2GridSection>
      {items.length > 0 &&
        items.map((item, index) => {
          return <Cardv2 key={index} {...item} />;
        })}
    </Cardv2GridSection>
  );
};

export default Cardv2Grid;
