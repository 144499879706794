import { ButtonSection } from "./ArrowButton.styles";

const ArrowButton = ({ Icon , handleClick}) => {
  return (
    <ButtonSection onClick={handleClick}>
     <Icon />
    </ButtonSection>
  );
};

export default ArrowButton;
