import Home from "./pages/Home";
import Category from "./pages/Gategory";
import { Routes, Route, useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import GlobalStyles from "./components/GlobalStyles";
import KioskUiProvider from "./components/KioskUiProvider";
import theme from "./theme";
import "./index.css";
import MapPage from "./pages/MapPage";
import Documentation from "./pages/Documentation";
import QrCodePage from "./pages/QrCodePage";
import { useCallback, useEffect, useRef, useState } from "react";
import { fetchData } from "./pages/services";
import ScreenSaver from "./components/ScreenSaver";

const SCREENSAVER_DELAY_MS = 90000;
const SCREENSAVER_ACTIVE_TIME_MS = 8760 * 3600000;
const SCREENSAVER_INACTIVE_TIME_MS = 0;

function App() {
  const queryClient = new QueryClient();

  ////ScreenSaver////
  const [screenSaverList, setScreenSaverList] = useState();
  const [screensaverActive, setScreensaverActive] = useState(true);
  const [screensaverVisible, setScreensaverVisible] = useState(true);
  const [delay, setDelay] = useState();
  const screensaverTimeout = useRef();
  const secondTimer = useRef();
  const navigate = useNavigate();

  const activeScreensaver = useCallback(() => {
    navigate("/");
    setScreensaverActive(true);
    setScreensaverVisible(true);
    loop();
    function loop() {
      const timerRef = setTimeout(() => {
        setScreensaverVisible(false);
        const timerRef2 = setTimeout(() => {
          setScreensaverVisible(true);
          loop();
        }, SCREENSAVER_INACTIVE_TIME_MS);
        secondTimer.current = timerRef2;
      }, SCREENSAVER_ACTIVE_TIME_MS);
      secondTimer.current = timerRef;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchData().then((data) => {
      setDelay(data?.screenSaver[0]?.delay)
      reformattedData(data?.screenSaver[0]?.media);
    });
    activeScreensaver();
    // eslint-disable-next-line
  }, []);

  const reformattedData = async (data) => {
    const list = await Promise.all(
      data?.filter(function (element) {
        return element?.mimetype !== "application/pdf";
      })
    );
    setScreenSaverList(
      list?.map((item) => {
        return {
          src: item?.location,
        };
      })
    );
  };

  const screensaverClicked = useCallback(() => {
    setScreensaverActive(false);
    startTimeout();
    // eslint-disable-next-line
  }, []);

  const startTimeout = useCallback(() => {
    clearTimeout(screensaverTimeout.current);
    clearTimeout(secondTimer.current);
    const timeout = setTimeout(() => activeScreensaver(), SCREENSAVER_DELAY_MS);
    screensaverTimeout.current = timeout;
    // eslint-disable-next-line
  }, []);

  const appTouched = useCallback((ev) => {
    if (ev.target.id !== "screensaver") {
      startTimeout();
    }
    // eslint-disable-next-line
  }, []);
  ////ScreenSaver////

  return (
    <QueryClientProvider client={queryClient}>
      <KioskUiProvider theme={theme}>
        <GlobalStyles />
        <div className="App" onClick={appTouched}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/category/:id" element={<Category />} />
            <Route path="/plan" element={<MapPage />} />
            <Route path="/documentation" element={<Documentation />} />
            <Route path="/pdf/:id" element={<QrCodePage />} />
            <Route path="*" element={<Home />} />
          </Routes>
          {screensaverActive && screensaverVisible && screenSaverList?.length && (
            <div
              id="screensaver"
              style={styles.screensaver}
              onClick={screensaverClicked}
            >
              <ScreenSaver items={screenSaverList} delay={delay} />
            </div>
          )}
        </div>
      </KioskUiProvider>
    </QueryClientProvider>
  );
}

const styles = {
  screensaver: {
    position: "fixed",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.90)",
    zIndex: 100,
  },
};

export default App;
