const NotBad = function ({ width, height, color }) {
  return (
    <svg
      width={width ? width : "180"}
      height={height ? height : "180"}
      viewBox="0 0 180 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M90.0081 0.00779724C40.3121 0.00779724 0.0161133 40.3038 0.0161133 89.9998C0.0161133 139.696 40.3121 179.992 90.0081 179.992C139.704 179.992 180 139.696 180 89.9998C180 40.3038 139.704 0.00779724 90.0081 0.00779724ZM126.048 58.7258C133.884 58.7258 140.228 65.0852 140.228 72.9054C140.228 80.7256 133.868 87.085 126.048 87.085C118.228 87.085 111.869 80.7256 111.869 72.9054C111.869 65.0852 118.212 58.7258 126.048 58.7258ZM53.9541 58.7258C61.7901 58.7258 68.1337 65.0852 68.1337 72.9054C68.1337 80.7256 61.7743 87.085 53.9541 87.085C46.1337 87.085 39.7745 80.7256 39.7745 72.9054C39.7745 65.0852 46.1183 58.7258 53.9541 58.7258ZM140.244 116.594H39.7601C37.5569 116.594 35.7601 114.797 35.7601 112.594C35.7601 110.391 37.557 108.594 39.7601 108.594H140.228C142.431 108.594 144.228 110.391 144.228 112.594C144.228 114.797 142.447 116.594 140.244 116.594H140.244Z"
        fill="url(#paint0_linear_75_2529)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_75_2529"
          x1="90.0081"
          y1="0.00779724"
          x2="90.0081"
          y2="179.992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F0B371" />
          <stop offset="1" stop-color="#E99A51" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NotBad;
