import tw, { styled } from "twin.macro";

//Object variants
export const CardsVariant = {
  md: tw`flex [&>*]:w-full [&>*]:h-[46.5rem] [&>*]:rounded-t-[3rem] [&>*]:object-cover `,
  sm: tw`flex [&>*]:w-full [&>*]:h-[30.563rem] [&>*]:rounded-t-[3rem] [&>*]:object-cover`,
};

export const CardContainer = styled.div(({ theme, variant }) => [
  tw`w-[55.188rem]  bg-white rounded-[3rem] border shadow-lg`,
  variant === "sm" ? tw`h-[85.25rem]` : tw`h-[98rem]`,
  //base style
  `font-family: ${theme.fontFamily.secondary};`,
  `-webkit-column-break-inside: avoid;`,
]);

export const CardImage = styled.div(() => [
  //variants
  ({ variant }) => CardsVariant[variant],
]);

export const ContentCard = tw.div`max-h-[51.5rem] p-12 flex flex-col items-center`;

export const Title = styled.span(({ theme, titleColor }) => [
  tw`font-semibold text-[4rem] leading-[4.92rem]`,
  //Colors
  titleColor ? `color: ${titleColor};` : `color: ${theme.colors.text.primary};`,
]);

export const BtnSection = tw.div`flex items-center flex-col gap-8 mt-[8rem] mb-[4rem] w-[45.744rem]`;

export const FirstBtn = styled.button(() => [
  tw`flex justify-center items-center w-full font-semibold text-[3.25rem] leading-[4rem] py-4 border-8 bg-white border-[#0E1222] rounded-full gap-4`,
  `box-shadow: 2.6px 5.2px 31.2px rgba(0, 0, 0, 0.45);`,
]);

export const SecondBtn = styled.button(() => [
  tw`flex justify-center items-center  w-full font-semibold text-[3.25rem] leading-[4rem] py-4 border-8 bg-[#0E1222] border-white text-white rounded-full gap-4`,
  `box-shadow: 2.6px 5.2px 31.2px rgba(0, 0, 0, 0.45);`,
]);

export const PDFDocumentWrapper = styled.div(({ variant }) => [
  variant === "md"
    ? `
  canvas {
    width: 100% !important;
    height: 744px !important;
    object-fit: cover !important;
    border-top-left-radius: 48px;
    border-top-right-radius: 48px;
  }
`
    : `
  canvas {
    width: 100% !important;
    height: 489px !important;
    object-fit: cover !important;
    border-top-left-radius: 48px;
    border-top-right-radius: 48px;
    }
`,
]);
