const Polygon = function ({ width, height, color }) {
  return (
    <svg
      width="112"
      height="103"
      viewBox="0 0 112 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_75_139)">
        <path
          d="M61.7942 78.5C58.3301 84.5 49.6699 84.5 46.2058 78.5L15.8949 26C12.4308 20 16.7609 12.5 23.6891 12.5L84.3109 12.5C91.2391 12.5 95.5692 20 92.1051 26L61.7942 78.5Z"
          fill="#477C7D"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_75_139"
          x="0.675781"
          y="0.499992"
          width="110.648"
          height="102.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="4" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_75_139"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_75_139"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Polygon;
