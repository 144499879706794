export const isEmpty = (any) => {
  if (!any) return true;
  if (any === undefined) return true;
  if (any === "undefined") return true;
  if (typeof any === "undefined") return true;
  if (any.length === 0) return true;
  if (JSON.stringify(any) === "{}") return true;
  return false;
};

export const getImage = (data) => {
  const image =  data?.filter((item) => {
    return item.mimetype === "image/png" || item.mimetype === "image/jpeg";
  });

  if (image[0]) return image[0].location;
};

export const getPdfFile = (data) => {
  const pdf = data?.filter((item) => {
    return item.mimetype === "application/pdf";
  });

  if (pdf[0]) return pdf[0].location;
}
