const Terrible = function ({ width, height, color }) {
  return (
    <svg
      width={width ? width : "180"}
      height={height ? height : "180"}
      viewBox="0 0 180 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M90.0081 0.00779724C40.3121 0.00779724 0.0161133 40.3038 0.0161133 89.9998C0.0161133 139.696 40.3121 179.992 90.0081 179.992C139.704 179.992 180 139.696 180 89.9998C180 40.3038 139.704 0.00779724 90.0081 0.00779724ZM39.7981 86.2178C38.8372 84.2178 39.6809 81.835 41.6575 80.8818L59.0951 72.4834L41.5169 63.1866C39.556 62.1475 38.8137 59.7256 39.8607 57.7882C40.8997 55.8273 43.3217 55.085 45.2591 56.132L69.8371 69.132C71.1808 69.8351 71.9933 71.2336 71.9543 72.7492C71.9308 74.2492 71.0558 75.632 69.6965 76.2726L45.1185 88.1086C44.556 88.3664 43.9622 88.507 43.3763 88.507C41.9153 88.4992 40.4935 87.6633 39.7981 86.218L39.7981 86.2178ZM132.126 137.734H47.8761C43.1573 137.734 39.7355 133.21 41.0401 128.671C46.1807 110.796 66.1341 97.4754 89.9941 97.4754C113.854 97.4754 133.83 110.796 138.948 128.671C140.268 133.218 136.847 137.734 132.128 137.734H132.126ZM140.204 86.2178C139.525 87.6397 138.087 88.4756 136.603 88.4756C136.025 88.4756 135.423 88.3584 134.861 88.0772L110.283 76.2412C108.923 75.5849 108.04 74.2178 108.025 72.7178C108.001 71.2178 108.822 69.8194 110.142 69.1006L134.72 56.1006C136.681 55.0615 139.103 55.8193 140.118 57.7568C141.157 59.7177 140.4 62.1396 138.462 63.1552L120.884 72.452L138.322 80.8504C140.329 81.8426 141.165 84.241 140.204 86.2176V86.2178Z"
        fill="url(#paint0_linear_75_2531)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_75_2531"
          x1="90.0081"
          y1="0.00779724"
          x2="90.0081"
          y2="179.992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E55675" />
          <stop offset="1" stop-color="#D87062" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Terrible;
