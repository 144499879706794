import { formatAMPM, getweather } from "../../pages/services";
import { useEffect, useState } from "react";
import Dropdown from "../Dropdown";
import {
  City,
  DropdownContainer,
  HeaderDay,
  HeaderTime,
  StyledHeader,
  StyledHeaderLogo,
  StyledHeaderText,
  StyledHeaderWeather,
  WeatherWrapper,
} from "./Header.styles";

const Header = ({
  day,
  logo,
  // WeatherIcon,
  // weather,
  location,
  city,
  variant,
  languages,
}) => {
  const [cTime, setTime] = useState(formatAMPM(new Date()));
  const [weather, setWeather] = useState();
  const [weatherIcon, setWeatherIcon] = useState();

  //Update Time
  useEffect(() => {
    const id = setInterval(() => {
      setTime(formatAMPM(new Date()));
    }, 1000);
    return () => clearInterval(id);
  }, []);

  //Get Weather and WeatherIcon
  useEffect(() => {
    location &&
      getweather(location).then(function (result) {
        setWeather(Math.round(parseFloat(result.main.temp)));
        setWeatherIcon(result.weather[0].icon);
      });
  }, [location, cTime]);

  return (
    <StyledHeader>
      <StyledHeaderText variant={variant}>
        <HeaderTime>{cTime}</HeaderTime>
        <HeaderDay>{day}</HeaderDay>
      </StyledHeaderText>
      <StyledHeaderLogo>
        {logo && <img src={logo} alt="logo" />}
      </StyledHeaderLogo>
      <StyledHeaderWeather variant={variant}>
        {weatherIcon && (
          <img
            width={190}
            height={190}
            src={`weatherIcons/${weatherIcon}.svg`}
            alt="icon"
          />
        )}
        {/* {WeatherIcon && <WeatherIcon />} */}
        {weather && (
          <WeatherWrapper>
            {weather + "°C"}
            <City>{city}</City>
          </WeatherWrapper>
        )}
      </StyledHeaderWeather>
      <DropdownContainer variant={variant}>
        {variant === "secondary" && <Dropdown languages={languages} />}
      </DropdownContainer>
    </StyledHeader>
  );
};

export default Header;
