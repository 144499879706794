import { Menu } from "@headlessui/react";
import {
  StyledDropdown,
  List,
  ListItem,
  Label,
  ToggleButton,
} from "./Dropdown.styles";
import { isEmpty } from "../../helpers/utils";
import { ChevronDown } from "../Icons";
import i18next from "i18next";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const Dropdown = ({ languages, load }) => {
  return (
    <StyledDropdown>
      <Menu as="div" className="menu">
        {({ open }) => (
          <>
            <Menu.Button>
              <span
                className={`fi fi-${
                  i18next.language === "en" ? "gb" : i18next.language
                }`}
              ></span>
              {i18next.language}
              <ToggleButton open={open}>
                <ChevronDown />
              </ToggleButton>
            </Menu.Button>
            <Menu.Items>
              <List>
                {!isEmpty(languages) &&
                  languages.map((item, index) => (
                    <Menu.Item key={index} >
                      {({ active }) => (
                        <ListItem
                          active={active}
                          onClick={() => {
                            load?.setLoadHeaderContent(!load.LoadheaderContent)
                            i18next.changeLanguage(item.language);
                          }}
                        >
                          <span
                            className={`fi fi-${
                              item?.language === "en"
                                ? "gb"
                                : item?.language
                            }`}
                          ></span>
                          <Label>{item?.language}</Label>
                        </ListItem>
                      )}
                    </Menu.Item>
                  ))}
              </List>
            </Menu.Items>
          </>
        )}
      </Menu>
    </StyledDropdown>
  );
};

export default Dropdown;
