import { useEffect, useState } from "react";
import Cardv2Grid from "../../components/Card_v2Grid";
import BackbuttonField from "../../components/BackbuttonField";
import Header from "../../components/Header";
import { CardSection } from "./Documentation.styles";
import { fetchData, getLocation } from "../services";
import { useNavigate } from "react-router-dom";
import ModalBlur from "../../components/ModalBlur";
import PdfModel from "../../components/PdfModel";

const Documentation = () => {
  const [locales, setLocales] = useState();
  const [file, setFile] = useState();
  const [title, setTitle] = useState();
  const [id, setId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [reformattedCards, setReformattedCards] = useState();
  const [reformattedHeader, setReformattedHeader] = useState({});
  const [reformattedBackbuttonField, setReformattedBackbuttonField] = useState(
    {}
  );
  const navigate = useNavigate();

  useEffect(() => {
    fetchData().then((data) => {
      handleReformattedBackbuttonField();
      handleReformattedCards(data?.documents);
      //Get Locales
      getLocation(data).then(function (res) {
        handleReformattedHeader(res);
        setLocales(res?.locales);
      });
    });
    // eslint-disable-next-line
  }, []);

  //Reformatte Header
  const handleReformattedHeader = (data) => {
    setReformattedHeader({
      variant: "secondary",
      logo: data?.logo,
    });
  };

  //Reformatte BackbuttonField
  const handleReformattedBackbuttonField = () => {
    setReformattedBackbuttonField({
      label: "back",
      text: "map",
      onClick: () => navigate("/"),
    });
  };

  //Reformatte Language
  const reformattedLanguge = locales?.map((data) => {
    return {
      language: data,
    };
  });

  //Reformatte Cards
  const handleReformattedCards = async (documents) => {
    setReformattedCards(
      await Promise.all(
        documents?.map((document, index) => {
          return {
            variant: index % 2 ? "sm" : "md",
            image: document?.document[0].location,
            title: document?.name,
            btnlabel1: "preview",
            btnlabel2: "getDocument",
            previewClick: () => {
              setFile(document?.document[0].location);
              setTitle(document?.name);
              setId(document._id);
              setShowModal(true);
            },
            docClick: () => navigate(`/pdf/${document._id}`),
          };
        })
      )
    );
  };
  return (
    <>
      {showModal && (
        <ModalBlur>
          <PdfModel
            title={title}
            file={file}
            btnlabel="getDocument"
            showModal={setShowModal}
            onClick={() => navigate(`/pdf/${id}`)}
          />
        </ModalBlur>
      )}
      {reformattedHeader && (
        <Header languages={reformattedLanguge} {...reformattedHeader} />
      )}
      <BackbuttonField {...reformattedBackbuttonField} />
      <CardSection>
        {reformattedCards && <Cardv2Grid items={reformattedCards} />}
      </CardSection>
    </>
  );
};

export default Documentation;
