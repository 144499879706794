import intervalToDuration from "date-fns/intervalToDuration";

const {
  API_BASE_URL,
  API_KEY,
  API_WEATHER_URL,
  API_PLACES,
} = require("../config/constants");

export async function fetchData() {
  return await fetch(API_BASE_URL).then((response) => response.json());
}

export async function mainCategories(fetchedData) {
  let data = fetchedData?.categories;
  data = data?.filter((item) => {
    return !item.parent || item.parent === item._id;
    // return item.parent
  });
  return data;
}

export async function getLocation(fetchedData) {
  let location = fetchedData?.location;
  return location;
}

export async function getPlaces(location) {
  return await fetch(
    API_PLACES + location[1] + "," + location[0] + ",5000" 
  ).then((response) => response.json());
}

export async function getweather(location) {
  return await fetch(
    API_WEATHER_URL +
      location[0] +
      "&lon=" +
      location[1] +
      "&appid=" +
      API_KEY +
      "&units=metric"
  ).then((response) => response.json());
}

export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export function formatDate(language) {
  const currentDate = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };
  return currentDate.toLocaleDateString(language, options);
}

export default function floatHourToDuration(hour) {
  const interval = intervalToDuration({
    start: 0,
    end: hour * 60 * 60 * 1000,
  });
  return {
    ...interval,
    text: `${interval.hours}:${interval.minutes || "00"}`,
  };
}
