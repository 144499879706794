import {
  Body,
  BtnSection,
  CardContainer,
  CardImage,
  ContentCard,
  Direction,
  Header,
  Line,
  LinkLabel,
  LinkSection,
  Time,
  Title,
} from "./Card_v1.styles";
import { Clock, Location, Plus } from "../../Icons";
import Menu from "../../Icons/menu";
import Tag from "../../Tag";
import ButtonV1 from "../../Button_v1";
import { useTranslation } from "react-i18next";

const Cardv1 = function ({
  image,
  title,
  titleColor,
  article,
  time,
  linkLabel,
  direction,
  variant = "primary",
  btnlabel,
  linkFile,
  handleClick,
}) {
  const { t } = useTranslation();
  return (
    <CardContainer variant={variant}>
      <CardImage variant={variant}>
        <img src={image} alt="test" />
      </CardImage>
      <ContentCard>
        <Header>
          <Title titleColor={titleColor} variant={variant}>
            {t(title)}
          </Title>
        </Header>
        <Body variant={variant}>{article}</Body>
        <BtnSection>
          <Direction variant={variant}>
            <Location width="5rem" />
            {t("distance") + ":  " + direction + " M"}
          </Direction>
          {variant === "primary" ? (
            <Tag label={btnlabel}></Tag>
          ) : (
            <ButtonV1 label={btnlabel} Icon={Plus} handleClick={handleClick} />
          )}
        </BtnSection>
        {time && (
          <Time variant={variant}>
            <Clock width="5.209rem" />
            {t("open") +
              " " +
              time?.open +
              " - " +
              t("close") +
              " " +
              time?.close}
          </Time>
        )}
        {linkFile && (
          <>
            <Line variant={variant}></Line>
            <LinkSection variant={variant}>
              <Menu width="4.493rem" />
              <LinkLabel>{t(linkLabel)}</LinkLabel>
            </LinkSection>
          </>
        )}
      </ContentCard>
    </CardContainer>
  );
};

export default Cardv1;
