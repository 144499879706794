const Location = function ({ width, height, color }) {
  return (
    <svg
      width={width} // added size here
      height={height} // added size here
      fill={color} // added color here
      viewBox="0 0 80 80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.667 16.6666C8.91033 16.6666 6.66699 14.4233 6.66699 11.6666C6.66699 8.90996 8.91033 6.66663 11.667 6.66663C14.4237 6.66663 16.667 8.90996 16.667 11.6666C16.667 14.4233 14.4237 16.6666 11.667 16.6666ZM11.667 9.99996C10.747 9.99996 10.0003 10.7466 10.0003 11.6666C10.0003 12.5866 10.747 13.3333 11.667 13.3333C12.587 13.3333 13.3337 12.5866 13.3337 11.6666C13.3337 10.7466 12.587 9.99996 11.667 9.99996Z"
        fill="#0E1222"
      />
      <path
        d="M11.6667 33.3333C11.12 33.3333 10.6067 33.0633 10.2933 32.6133C9.24 31.09 0 17.54 0 11.6667C0 5.23333 5.23333 0 11.6667 0C18.1 0 23.3333 5.23333 23.3333 11.6667C23.3333 17.54 14.0933 31.09 13.04 32.6133C12.7267 33.0633 12.2133 33.3333 11.6667 33.3333ZM11.6667 3.33333C7.07333 3.33333 3.33333 7.07333 3.33333 11.6667C3.33333 14.89 7.99333 23.0733 11.6667 28.68C15.34 23.0733 20 14.89 20 11.6667C20 7.07333 16.26 3.33333 11.6667 3.33333Z"
        fill="#0E1222"
      />
      <path
        d="M65.0003 56.6667C60.407 56.6667 56.667 52.9267 56.667 48.3333C56.667 43.74 60.407 40 65.0003 40C69.5937 40 73.3337 43.74 73.3337 48.3333C73.3337 52.9267 69.5937 56.6667 65.0003 56.6667ZM65.0003 43.3333C62.2437 43.3333 60.0003 45.5767 60.0003 48.3333C60.0003 51.09 62.2437 53.3333 65.0003 53.3333C67.757 53.3333 70.0003 51.09 70.0003 48.3333C70.0003 45.5767 67.757 43.3333 65.0003 43.3333Z"
        fill="#0E1222"
      />
      <path
        d="M65 80C64.41 80 63.8633 79.6867 63.5633 79.18C62.1767 76.8234 50 55.93 50 48.3334C50 40.0634 56.73 33.3334 65 33.3334C73.27 33.3334 80 40.0634 80 48.3334C80 55.93 67.8233 76.8234 66.4367 79.18C66.1367 79.6867 65.59 80 65 80ZM65 36.6667C58.5667 36.6667 53.3333 41.9 53.3333 48.3334C53.3333 53.36 60.7733 67.54 65 74.9967C69.2267 67.54 76.6667 53.36 76.6667 48.3334C76.6667 41.9 71.4333 36.6667 65 36.6667Z"
        fill="#0E1222"
      />
      <path
        d="M58.3337 80C55.7937 80 53.4637 79.79 51.407 79.3733C50.5037 79.19 49.9237 78.31 50.1037 77.4067C50.287 76.5033 51.1503 75.93 52.0703 76.1033C53.9103 76.4767 56.017 76.6667 58.3337 76.6667C59.2537 76.6667 60.0003 77.4133 60.0003 78.3333C60.0003 79.2533 59.2537 80 58.3337 80ZM45.597 77.0467C45.2903 77.0467 44.9803 76.9633 44.7003 76.7833C42.5803 75.43 40.9303 73.68 39.657 71.44C39.2003 70.64 39.4837 69.6233 40.2837 69.1667C41.0837 68.71 42.1003 68.9933 42.557 69.7933C43.557 71.5533 44.8437 72.92 46.497 73.9733C47.2737 74.4667 47.5003 75.5 47.0037 76.2733C46.687 76.7733 46.147 77.0467 45.597 77.0467ZM38.9037 66.03C38.127 66.03 37.4337 65.4833 37.2737 64.6967C36.787 62.31 36.5503 59.7967 36.397 57.8767C36.3237 56.96 37.0103 56.1567 37.927 56.0833C38.8403 56.0033 39.647 56.6967 39.7203 57.6133C39.8637 59.44 40.0903 61.82 40.5403 64.0333C40.7237 64.9367 40.1403 65.8167 39.2403 65.9967C39.127 66.02 39.017 66.03 38.9037 66.03ZM37.5803 52.7467C36.7237 52.7467 35.9937 52.09 35.9203 51.22C35.6937 48.5433 35.4203 46.5767 35.0237 44.8433C34.817 43.9467 35.3803 43.0533 36.277 42.8467C37.1637 42.6367 38.067 43.2033 38.2737 44.1033C38.7037 45.9933 39.0003 48.1033 39.2403 50.9367C39.317 51.8533 38.637 52.66 37.7203 52.7367C37.6737 52.7433 37.627 52.7467 37.5803 52.7467ZM34.2203 39.9933C33.6737 39.9933 33.137 39.7233 32.8203 39.23C31.7537 37.58 30.3803 36.3233 28.6203 35.3867C27.807 34.9533 27.5003 33.9433 27.9303 33.1333C28.3637 32.3233 29.3737 32.0167 30.1837 32.4433C32.4237 33.6367 34.2537 35.31 35.617 37.42C36.117 38.19 35.8937 39.2233 35.1237 39.7233C34.8437 39.9067 34.5303 39.9933 34.2203 39.9933ZM23.1103 33.6467C23.0337 33.6467 22.9537 33.6433 22.8703 33.63C21.487 33.4333 19.917 33.3767 18.3337 33.3333C17.4137 33.3333 16.667 32.5867 16.667 31.6667C16.667 30.7333 17.4537 29.9267 18.3703 30C20.1403 30 21.8137 30.11 23.3437 30.33C24.2537 30.4633 24.887 31.3067 24.757 32.2167C24.637 33.05 23.9237 33.6467 23.1103 33.6467Z"
        fill="#0E1222"
      />
    </svg>
  );
};

export default Location;
