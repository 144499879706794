import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, { Autoplay } from "swiper";
import { Pagination } from "swiper";
import { DropdownContainer, Slide } from "./Swiper.styles";
import Dropdown from "../Dropdown";

const Swiperv1 = ({ items, languages, load }) => {
  SwiperCore.use([Autoplay]);
  return (
    <>
      <DropdownContainer>
        <Dropdown languages={languages} load={load} />
      </DropdownContainer>
      <Swiper
        modules={[Pagination]}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 2000,
        }}
        pagination={{ clickable: false }}
      >
        {items?.length > 0 &&
          items.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <Slide src={item.src} />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </>
  );
};

export default Swiperv1;
