import {
  BtnSection,
  CardContainer,
  CardImage,
  ContentCard,
  FirstBtn,
  PDFDocumentWrapper,
  SecondBtn,
  Title,
} from "./Card_v2.styles";
import { Eye, Qrcode } from "../../Icons";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
import prependHttp from 'prepend-http';

const Cardv2 = function ({
  image,
  title,
  titleColor,
  variant = "md",
  btnlabel1,
  btnlabel2,
  previewClick,
  docClick,
}) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const { t } = useTranslation();
  return (
    <CardContainer variant={variant}>
      <CardImage variant={variant}>
        <PDFDocumentWrapper variant={variant}>
          <Document file={prependHttp(image)}>
            <Page pageNumber={1} />
          </Document>
        </PDFDocumentWrapper>
      </CardImage>
      <ContentCard>
        <Title titleColor={titleColor} variant={variant}>
          {t(title)}
        </Title>
        <BtnSection>
          <FirstBtn onClick={previewClick}>
            <Eye width="5rem" />
            {t(btnlabel1)}
          </FirstBtn>
          <SecondBtn onClick={docClick}>
            <Qrcode width="5rem" />
            {t(btnlabel2)}
          </SecondBtn>
        </BtnSection>
      </ContentCard>
    </CardContainer>
  );
};

export default Cardv2;
