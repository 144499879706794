import { useEffect, useState } from "react";
import BackbuttonField from "../../components/BackbuttonField";
import Header from "../../components/Header";
import Map from "../../components/Map";
import { fetchData, getPlaces } from "../services";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";

const MapPage = () => {
  const [locales, setLocales] = useState();
  const [reformattedMap, setReformattedMap] = useState();
  const [clonnedMap, setClonnedMap] = useState();
  const [reformattedHeader, setReformattedHeader] = useState({});
  const [location, setLocation] = useState();
  const [reformattedBackbuttonField, setReformattedBackbuttonField] = useState(
    {}
  );
  const navigate = useNavigate();

  useEffect(() => {
    fetchData().then((data) => {
      handleReformattedBackbuttonField();
      handleReformattedHeader(data.location);
      setLocales(data.location.locales);
      setLocation(data.location);
      getPlaces(data.location.loc).then((places) => {
        handleReformattedMap(places.data);
      });
    });
    // eslint-disable-next-line
  }, []);

  //Reformatte Header
  const handleReformattedHeader = (data) => {
    setReformattedHeader({
      variant: "secondary",
      logo: data?.logo,
    });
  };

  //Reformatte BackbuttonField
  const handleReformattedBackbuttonField = () => {
    setReformattedBackbuttonField({
      label: "back",
      text: "plan",
      onClick: () => navigate("/"),
    });
  };

  //Reformatte Language
  const reformattedLanguge = locales?.map((data) => {
    return {
      language: data,
    };
  });

  //Reformatte Map
  const handleReformattedMap = async (places) => {
    setReformattedMap(
      await Promise.all(
        places?.map((place) => {
          return {
            long: place?.location?.coordinates[0],
            lat: place?.location?.coordinates[1],
            label: "100m",
            image: place?.media[0]?.url ,
            title: place?.title[i18next.language],
            article: place?.description[i18next.language],
            direction: "120",
          };
        })
      )
    );
  };

  useEffect(() => {
    if (reformattedMap && location) {
      setClonnedMap(() => [
        ...reformattedMap,
        {
          long: location?.loc[0],
          lat: location?.loc[1],
          label: "you",
          currentPos: true,
          image: location?.logo,
          title: location?.name,
          article:
            "Magna mi ac sem fusce amet metus iaculis tellus. Ac vitae erat dui odio vel vehicula tempus scelerisque imperdiet.",
          direction: "120",
        },
      ]);
    }
    // eslint-disable-next-line
  }, [reformattedMap]);
  return (
    <>
      {reformattedHeader && (
        <Header languages={reformattedLanguge} {...reformattedHeader} />
      )}
      <BackbuttonField {...reformattedBackbuttonField} />
      {clonnedMap && (
        <Map
          markers={clonnedMap}
          btnlabel="getDocument"
          btnClick={() => console.log("btn clicked")}
        />
      )}
    </>
  );
};

export default MapPage;
