import tw, { styled } from "twin.macro";

export const MapContainer = tw.div`
    w-full relative
`;

export const QrcodeBtn = styled.button(() => [
  tw`absolute z-1 right-[5.694rem] top-[3.781rem] flex justify-center items-center w-[45.744rem] font-semibold text-[3.25rem] leading-[4rem] py-4 border-8 bg-[#0E1222] border-white text-white rounded-full gap-4`,
  `box-shadow: 2.6px 5.2px 31.2px rgba(0, 0, 0, 0.45);`,
]);
