const Meeting = function ({ width, height, color }) {
  return (
    <svg
      width={width ? width : "127"}
      height={height ? height : "133"}
      viewBox="0 0 127 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.1934 0.692688H27.1726C23.6414 0.692688 20.7819 3.55202 20.7819 7.08335V53.5527C20.7819 57.0839 23.6412 59.9434 27.1726 59.9434H100.167C103.698 59.9434 106.558 57.084 106.558 53.5527V7.08335C106.558 3.55216 103.699 0.692688 100.167 0.692688H38.5992C37.4482 0.692688 36.5159 1.62498 36.5159 2.77602C36.5159 3.92706 37.4482 4.85935 38.5992 4.85935H100.167C101.396 4.85935 102.391 5.85414 102.391 7.08335V53.5527C102.391 54.7819 101.396 55.7767 100.167 55.7767H27.1726C25.9434 55.7767 24.9486 54.7819 24.9486 53.5527V7.08335C24.9486 5.85418 25.9433 4.85935 27.1726 4.85935H30.1934C31.3444 4.85935 32.2767 3.92706 32.2767 2.77602C32.2767 1.62498 31.3444 0.692688 30.1934 0.692688Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.068 95.3333V108.839C15.068 109.99 16.0003 110.922 17.1513 110.922C18.3024 110.922 19.2347 109.99 19.2347 108.839V91.7707C19.2347 90.6092 18.5211 89.5728 17.4377 89.1561C16.3544 88.7395 15.1304 89.0364 14.3492 89.8957L6.00548 99.1665C4.23988 101.13 3.26068 103.682 3.26068 106.323V121.953C3.26068 123.105 4.19297 124.037 5.34401 124.037C6.49505 124.037 7.42735 123.105 7.42735 121.953V106.323C7.42735 104.708 8.02631 103.151 9.10442 101.953L15.068 95.328V95.3333ZM112.261 95.3333L118.225 101.958C119.303 103.156 119.902 104.714 119.902 106.328V121.959C119.902 123.11 120.834 124.042 121.985 124.042C123.136 124.042 124.069 123.11 124.069 121.959V106.328C124.069 103.687 123.09 101.135 121.324 99.1719L112.98 89.9011C112.204 89.0365 110.975 88.7448 109.892 89.1615C108.808 89.5782 108.095 90.6146 108.095 91.776V108.844C108.095 109.995 109.027 110.927 110.178 110.927C111.329 110.927 112.261 109.995 112.261 108.844V95.3333ZM91.412 74.484L97.3756 81.1091C98.4537 82.307 99.0527 83.8643 99.0527 85.4788V98.7965C99.0527 99.9476 99.985 100.88 101.136 100.88C102.287 100.88 103.219 99.9476 103.219 98.7965V85.4788C103.219 82.8381 102.24 80.2861 100.475 78.3225L92.1308 69.0517C91.3548 68.1924 90.1256 67.8955 89.0423 68.3122C87.9589 68.7288 87.2453 69.7652 87.2453 70.9267V85.676C87.2453 86.8271 88.1776 87.7594 89.3287 87.7594C90.4797 87.7594 91.412 86.8271 91.412 85.676V74.484ZM35.9227 74.484V85.6767C35.9227 86.8277 36.855 87.76 38.006 87.76C39.1571 87.76 40.0894 86.8277 40.0894 85.6767V70.9273C40.0894 69.7659 39.3758 68.7295 38.2924 68.3128C37.209 67.8961 35.9851 68.193 35.2039 69.0524L26.8601 78.3232C25.0945 80.2868 24.1153 82.8388 24.1153 85.4795V87.4169C24.1153 88.568 25.0476 89.5003 26.1987 89.5003C27.3497 89.5003 28.282 88.568 28.282 87.4169V85.4795C28.282 83.8649 28.881 82.3076 29.9591 81.1097L35.9227 74.484Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.1974 42.292C42.1974 42.292 47.7026 36.792 49.8432 34.6461C49.9891 34.5002 50.1922 34.4169 50.4005 34.4169H72.9058C74.2183 34.4169 75.4788 33.8961 76.411 32.9638L88.0829 21.292C88.8954 20.4795 88.8954 19.1565 88.0829 18.3441C87.2704 17.5316 85.9475 17.5316 85.1351 18.3441L73.4632 30.016C73.3121 30.1618 73.1142 30.2452 72.9059 30.2452H50.4006C49.088 30.2452 47.8276 30.766 46.8954 31.6982C44.7547 33.8441 39.2495 39.3441 39.2495 39.3441C38.437 40.1566 38.437 41.4796 39.2495 42.292C40.062 43.1044 41.385 43.1045 42.1974 42.292Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.584 57.864V75.4733C61.584 76.6243 62.5163 77.5566 63.6673 77.5566C64.8184 77.5566 65.7507 76.6243 65.7507 75.4733V57.864C65.7507 56.7129 64.8184 55.7806 63.6673 55.7806C62.5163 55.7806 61.584 56.7129 61.584 57.864Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.28121 132.64H120.057C122.583 132.64 124.87 131.156 125.896 128.848C126.922 126.541 126.495 123.848 124.807 121.973L82.5525 75.0253C81.615 73.9836 80.2765 73.3846 78.8703 73.3846H48.4743C47.068 73.3846 45.7295 73.9784 44.792 75.0253L2.53733 121.973C0.849867 123.848 0.4228 126.541 1.44879 128.848C2.47483 131.156 4.76132 132.64 7.28732 132.64H7.28121ZM7.28121 128.473C6.401 128.473 5.60935 127.958 5.25001 127.156C4.89064 126.354 5.04168 125.416 5.63023 124.765L47.8849 77.8169C48.0359 77.6503 48.2495 77.5565 48.4682 77.5565H78.8642C79.0882 77.5565 79.3017 77.6503 79.4476 77.8169L121.702 124.765C122.291 125.416 122.437 126.353 122.082 127.156C121.723 127.958 120.926 128.473 120.051 128.473H7.27524H7.28121Z"
        fill="white"
      />
    </svg>
  );
};

export default Meeting;
