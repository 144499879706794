const Restaurant2 = function ({ width, height, color }) {
  return (
    <svg
      width={width ? width : "103"}
      height={height ? height : "87"}
      viewBox="0 0 103 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.7612 0C55.8296 0.076997 54.9756 0.847521 54.8203 1.77199C54.626 2.69651 55.1306 3.69806 55.9847 4.12235C56.994 4.62312 57.6928 5.58644 58.586 6.66494C40.4551 8.28298 25.3139 25.4279 24.1873 43.4959H19.7227V47.8493H103V43.4959H98.5354C97.4094 25.4265 82.2676 8.28211 64.1367 6.66494C65.0299 5.58643 65.7287 4.62319 66.738 4.12235C67.592 3.69859 68.0967 2.61956 67.8639 1.69501C67.6311 0.732275 66.6995 0 65.7286 0H56.7612ZM61.3426 10.8644C77.8047 10.8644 92.7516 27.0074 93.9941 43.4956H28.7317C29.9743 27.0059 44.9212 10.8644 61.3441 10.8644H61.3426ZM30.5933 51.124C30.3991 51.124 30.2439 51.124 30.0887 51.1628L16.2672 54.8997L1.8634 57.0955C0.81562 57.2495 0 58.2128 0 59.2144V84.7956C0 86.0287 1.20353 87.1072 2.44611 86.9915L21.0424 84.7956C21.7798 84.7186 22.4402 84.2561 22.7505 83.6013L26.5553 76.1271H54.7803C55.2074 76.0883 55.6344 75.9731 55.9839 75.7421C63.7096 70.5794 71.5528 65.5325 79.2386 60.3315C81.141 58.6746 81.4905 55.9781 80.4031 54.2442C79.3162 52.5104 76.9869 51.5088 74.8125 52.3181C68.5615 54.9765 62.3111 57.6348 56.0596 60.2932C52.4491 60.4472 49.4207 60.409 46.897 60.2932L53.1479 58.6363C53.187 58.6363 53.2255 58.6363 53.2255 58.5976C55.2446 57.981 56.409 56.0932 56.409 54.3211C56.409 52.549 55.1664 50.6225 53.1479 50.0835C45.6935 50.1993 38.085 50.7383 30.592 51.085L30.5933 51.124ZM51.7139 54.1672C52.1018 54.1285 52.3737 54.437 52.0242 54.7838L35.5622 58.7905C34.7081 58.9832 34.0092 59.869 33.9702 60.7552C33.9311 61.6416 34.4748 62.5661 35.3289 62.8741C35.3289 62.8741 37.0762 63.5676 40.454 64.0296C43.7927 64.5304 48.9953 64.9541 56.5275 64.6462C56.7993 64.6462 57.0712 64.6074 57.304 64.4922L76.0945 56.3243C76.871 56.2473 76.7544 56.979 76.5607 57.0948L54.0816 71.7731H25.1968C24.3813 71.7731 23.6049 72.2356 23.2559 72.9674L22.0909 75.2795L19.878 58.4057L31.0592 55.4388L51.7139 54.1672ZM15.6464 59.4452L18.403 80.7116L4.38773 82.3685V61.1409L15.6464 59.4452Z"
        fill="white"
      />
    </svg>
  );
};

export default Restaurant2;
