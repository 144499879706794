import { QrContainer, StyledQrCode } from "./QrCode.styles";
import QRCode from "react-qr-code";
import QrDescription from "../QrDescription";

const QrCode = ({ text, value }) => {
  return (
    <QrContainer>
      <StyledQrCode>
        <QRCode
          size={1208}
          value={value}
          bgColor="#1E2334"
          fgColor="#fff"
          viewBox={`0 0 1208 1208`}
        />
      </StyledQrCode>
      <QrDescription text={text} />
    </QrContainer>
  );
};

export default QrCode;
