const Music = function ({ width, height, color }) {
  return (
    <svg
      width={width ? width : "89"}
      height={height ? height : "87"}
      viewBox="0 0 89 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.5567 60.7797V17.3014C29.5567 15.6419 30.8703 14.3144 32.1842 13.9824L84.7305 0.04253C87.0294 -0.289492 89 1.37003 89 3.36155V65.0948C89 74.7195 79.1477 80.03 70.9374 80.03C62.3986 80.03 52.8749 74.7197 52.8749 65.0948C52.8749 55.802 62.3986 50.4916 70.9374 50.4916C75.2069 50.4916 79.1477 51.4873 82.4318 53.8106V26.2629L36.1251 37.8792V72.0648C36.1251 81.6895 26.6014 87 18.0625 87C9.8523 87 0 81.6897 0 72.0648C0 62.772 9.8523 57.4616 18.0625 57.4616C22.332 57.4616 26.6014 58.7891 29.5569 60.7806L29.5567 60.7797ZM82.4309 19.2929V7.67662L36.1242 19.957V31.2413L82.4309 19.2929ZM70.9366 57.1294C66.3388 57.1294 59.4422 59.4527 59.4422 65.0948C59.4422 70.737 66.3388 73.3921 70.9366 73.3921C75.5343 73.3921 82.4309 70.7368 82.4309 65.0948C82.1024 59.4525 75.5343 57.1294 70.9366 57.1294ZM18.0624 64.0992C13.4647 64.0992 6.56805 66.7545 6.56805 72.0645C6.56805 77.7068 13.4647 80.3619 18.0624 80.3619C22.6601 80.3619 29.5567 77.7066 29.5567 72.0645C29.5567 66.7542 22.6601 64.0992 18.0624 64.0992Z"
        fill="white"
      />
    </svg>
  );
};

export default Music;
