const ArrowLeft2 = function ({ width, height, color }) {
  return (
    <svg
      width={width ? width : "62"}
      height={height ? height : "61"}
      viewBox="0 0 62 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clipRule="evenodd"
        d="M0.314453 30.5636L31.115 61.0005L36.0682 56.0473L14.0258 34.0031L61.6153 34.0031V26.9972L14.0258 26.9972L36.0682 4.95305L31.115 -0.0001297L0.314453 30.5636Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowLeft2;
