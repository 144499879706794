import { useTranslation } from "react-i18next";
import { ArrowLeft } from "../Icons";
import {
  StyledBackbuttonField,
  StyledBtn,
  Text,
} from "./BackbuttonField.styles";

const BackbuttonField = ({ label, text, onClick }) => {
  const { t } = useTranslation();
  return (
    <StyledBackbuttonField>
      <StyledBtn onClick={onClick}>
        <ArrowLeft />
        {t(label)}
      </StyledBtn>
      <Text>{t(text)}</Text>
    </StyledBackbuttonField>
  );
};

export default BackbuttonField;
