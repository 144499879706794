import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
import ArrowButton from "../ArrowButton";
import { ArrowLeft2, ArrowRight, Close, Qrcode } from "../Icons";
import {
  Buttonfield,
  CloseIcon,
  CurrentPage,
  ElementRight,
  Pages,
  PDFDocumentWrapper,
  PdfModelContainer,
  QrcodeBtn,
  Title,
  TitleContainer,
  TotalPages,
} from "./PdfModel.styles";

const PdfModel = ({ file, title, showModal, btnlabel,onClick }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { t } = useTranslation();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => {
    pageNumber > 1 && setPageNumber(pageNumber - 1);
  };

  const goToNextPage = () =>
    pageNumber < numPages && setPageNumber(pageNumber + 1);

  const twoInLength = (num) => {
    return num < 10 ? "0" + num?.toString() : num?.toString();
  };
  

  return (
    <PdfModelContainer>
      <TitleContainer>
        <Title>{title}</Title>
        <CloseIcon onClick={() => showModal(false)}>
          <Close color="white" />
        </CloseIcon>
      </TitleContainer>
      <PDFDocumentWrapper>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      </PDFDocumentWrapper>
      <Buttonfield>
        <QrcodeBtn onClick={onClick}>
          <Qrcode width="5rem" />
          {t(btnlabel)}
        </QrcodeBtn>
        <ElementRight>
          {numPages && (
            <>
              <Pages>
                <CurrentPage>{twoInLength(pageNumber)}/</CurrentPage>
                <TotalPages>{twoInLength(numPages)}</TotalPages>
              </Pages>
              <ArrowButton Icon={ArrowLeft2} handleClick={goToPrevPage} />
              <ArrowButton Icon={ArrowRight} handleClick={goToNextPage} />
            </>
          )}
        </ElementRight>
      </Buttonfield>
    </PdfModelContainer>
  );
};

export default PdfModel;
