import { useEffect, useState } from "react";
import BackbuttonField from "../../components/BackbuttonField";
import Header from "../../components/Header";
import QrCode from "../../components/QrCode";
import { QrSection } from "./QrCodePage.styles";
import { fetchData, getLocation } from "../services";
import { useNavigate, useLocation } from "react-router-dom";

const QrCodePage = () => {
  const [locales, setLocales] = useState();
  const [reformattedQrCode, setReformattedQrCode] = useState();
  const [reformattedHeader, setReformattedHeader] = useState({});
  const [reformattedBackbuttonField, setReformattedBackbuttonField] = useState(
    {}
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const id = location.pathname.split("/")[2];
    fetchData().then((data) => {
      const documents = data?.documents;
      const document = documents?.filter((doc) => doc._id === id);
      handleReformattedQrCode(document[0]?.document[0]?.location);
      handleReformattedBackbuttonField(document[0]?.name);
      //Get Locales
      getLocation(data).then(function (res) {
        handleReformattedHeader(res);
        setLocales(res?.locales);
      });
    });
    // eslint-disable-next-line
  }, []);

  //Reformatte Header
  const handleReformattedHeader = (data) => {
    setReformattedHeader({
      variant: "secondary",
      logo: data?.logo,
    });
  };

  //Reformatte BackbuttonField
  const handleReformattedBackbuttonField = (name) => {
    setReformattedBackbuttonField({
      label: "back",
      text: name,
      onClick: () => navigate("/documentation"),
    });
  };

  //Reformatte Language
  const reformattedLanguge = locales?.map((data) => {
    return {
      language: data,
    };
  });

  //Reformatte QrCode
  const handleReformattedQrCode = (pdf) => {
    setReformattedQrCode({
      value: pdf,
      text: "qrCode",
    });
  };

  return (
    <>
      {reformattedHeader && (
        <Header languages={reformattedLanguge} {...reformattedHeader} />
      )}
      <BackbuttonField {...reformattedBackbuttonField} />
      <QrSection>
        {reformattedQrCode && <QrCode {...reformattedQrCode} />}
      </QrSection>
    </>
  );
};

export default QrCodePage;
