import { Polygon } from "../Icons";
import { MapMarkerContainer, StyledMapMarker } from "./MapMarker.styles";

const MapMarker = ({ label, onClick, currentPos }) => {
  return (
    <StyledMapMarker onClick={onClick}>
      <MapMarkerContainer currentPos={currentPos}>{label}</MapMarkerContainer>
      <Polygon />
    </StyledMapMarker>
  );
};

export default MapMarker;
