import "mapbox-gl/dist/mapbox-gl.css";
import { MapContainer, QrcodeBtn } from "./Map.styles";
import ReactMapGl, {
  Marker,
  NavigationControl,
  GeolocateControl,
} from "react-map-gl";
import { useState } from "react";
import MapMarker from "../MapMarker";
import { Qrcode } from "../Icons";
import { useTranslation } from "react-i18next";
import { Cardv1 } from "../Cards";
import mapboxgl from "mapbox-gl"; 

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const token =
  "pk.eyJ1IjoieXNyYm9sbGVzIiwiYSI6ImNsNW03dm9qcjBienAzY3F2M2Q0cTFnbWMifQ.gFNmLudp0JkoMZj7dRQPKw";

const Map = ({ markers, label, btnlabel, btnClick, markerClick }) => {
  const [showCard, setShowCard] = useState(false);
  const [idCard, setIdCard] = useState();
  const [viewState, setViewState] = useState({
    latitude: markers[0].lat,
    longitude: markers[0].long,
    zoom: 15,
  });
  const { t } = useTranslation();

  return (
    <>
      <MapContainer>
        <QrcodeBtn onClick={btnClick}>
          <Qrcode width="5rem" />
          {t(btnlabel)}
        </QrcodeBtn>
        <ReactMapGl
          onMove={(evt) => setViewState(evt.viewState)}
          mapboxAccessToken={token}
          {...viewState}
          style={{ width: "100%", height: "100vh" }}
          mapStyle="mapbox://styles/mapbox/streets-v11"
        >
          <NavigationControl position="bottom-right" />
          <GeolocateControl position="bottom-right" />
          {markers.length > 0 &&
            markers.map((marker, index) => (
              <Marker
                latitude={marker?.lat}
                longitude={marker?.long}
                key={index}
              >
                <MapMarker
                  onClick={() => {setShowCard(!showCard)
                    setIdCard(index)}}
                  label={t(marker?.label)}
                  currentPos={marker?.currentPos}
                />
                {(idCard===index && showCard) && (
                  <Cardv1
                    key={index}
                    variant="secondary"
                    image={marker.image}
                    title={t(marker.title)}
                    article={t(marker.article)}
                    direction={marker.direction}
                    btnlabel={t("add")}
                    handleClick={() => console.log("you clicked me")}
                  />
                )}
              </Marker>
            ))}
        </ReactMapGl>
      </MapContainer>
    </>
  );
};
export default Map;
