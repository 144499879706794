import { API_REVIEW } from "../config/constants";

export const createReview = async (data) => {
  return await fetch(API_REVIEW, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((response) => response.json());
};
