import { useTranslation } from "react-i18next";
import { Info } from "../Icons";
import { Description, StyledQrDescription } from "./QrDescription.styles";

const QrDescription = ({ text }) => {
  const {t} = useTranslation()
  return (
    <StyledQrDescription>
      <Info />
      <Description>{t(text)}</Description>
    </StyledQrDescription>
  );
};

export default QrDescription;
