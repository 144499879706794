const ArrowLeft = function ({ width, height, color }) {
  return (
    <svg
      width={width ? width : "54"}
      height={height ? height : "32"}
      viewBox="0 0 54 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.1767 14.1724H6.88049L19.5921 3.19785C20.355 2.53947 20.4355 1.39321 19.7769 0.630633C19.1231 -0.131952 17.9717 -0.212465 17.2136 0.441172L0.630234 14.7638C0.232243 15.1048 0.00481674 15.6022 7.41235e-05 16.1279C-0.00466485 16.6537 0.218028 17.151 0.611289 17.5015L11.414 27.1309L17.0713 31.6069C17.403 31.8721 17.8057 32 18.199 32C18.7344 32 19.2698 31.7632 19.6299 31.3085C20.2553 30.5222 20.1179 29.3759 19.3314 28.7554L13.7546 24.3505L6.42954 17.8189H52.1806C53.185 17.8189 54 17.0042 54 16C53.9953 14.9864 53.1803 14.1717 52.1758 14.1717L52.1767 14.1724Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowLeft;
