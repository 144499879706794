import tw, { styled } from "twin.macro";

export const TagSection = styled.button(({ theme, label }) => [
  !label
  ? tw`hidden`
  :tw`flex justify-center items-center rounded-[2.5rem] font-normal text-[4rem] leading-[4.92rem] px-[4rem] py-[3rem] my-[3rem]`,
  `font-family: ${theme.fontFamily.secondary};`,
  `color: #000;`,
  `background-color: #F3F3F3;`,
  `border-style: solid;`,
  `border-width: 6px;`,
  `border-color: #DFDFDF;`,
]);
