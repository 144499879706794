import { useTranslation } from "react-i18next";
import ButtonV1 from "../Button_v1";
import { Shapes } from "../Icons";
import {
  StyledFeedBackCard,
  StyledFeedBackCardText,
} from "./FeedBackCard.styles";

const FeedBackCard = ({title, onClick}) => {
  const { t } = useTranslation();
  return (
    <StyledFeedBackCard>
      <Shapes className="absolute -left-[60rem] w-full h-[99rem]" />
      <StyledFeedBackCardText>
        {t(title)}
      </StyledFeedBackCardText>
      <ButtonV1
        label={t("feedback")}
        handleClick={onClick}
      />
    </StyledFeedBackCard>
  );
};

export default FeedBackCard;
