import { TagSection } from "./Tag.styles";

const Tag = ({ label}) => {
  return (
    <TagSection label={label}>
    {label}
    </TagSection>
  );
};

export default Tag;
