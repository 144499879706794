const Close = function ({ width, height, color }) {
  return (
    <svg
      width={width ? width : "148"}
      height={height ? height : "148"}
      viewBox="0 0 148 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.9116 0.769531C114.464 0.769531 147.23 33.5295 147.23 73.9125C147.23 114.465 114.47 147.231 73.9116 147.231C33.5346 147.231 0.768555 114.471 0.768555 73.9125C0.768555 33.5355 33.5286 0.769531 73.9116 0.769531ZM73.9116 61.8645L100.829 35.1225C108.798 27.1538 120.664 39.1947 112.87 47.1635L85.9525 73.9055L112.87 100.823C120.663 108.792 108.798 120.657 100.829 112.864L73.9116 85.9464L47.1696 112.864C39.2008 120.657 27.1596 108.792 35.1286 100.823L61.8706 73.9055L35.1286 47.1635C27.1599 39.1947 39.2008 27.1535 47.1696 35.1225L73.9116 61.8645ZM73.9116 4.30803C35.4801 4.30803 4.31455 35.4735 4.31455 73.905C4.31455 112.512 35.4801 143.679 73.9116 143.679C112.519 143.679 143.686 112.514 143.686 73.905C143.686 35.4735 112.52 4.30803 73.9116 4.30803Z"
        fill={color ? color : "#595959"}
      />
    </svg>
  );
};

export default Close;
