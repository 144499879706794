const Plan = function ({ width, height, color }) {
  return (
    <svg
      width={width ? width : "89"}
      height={height ? height : "99"}
      viewBox="0 0 89 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.15255 0V12.4654H0V18.2943H3.15217V31.8574H0V37.6805H3.15217V51.2601H0V57.089H3.15217V70.6685H0V76.4974H3.15217V86.5744H45.8336C49.6523 93.9454 57.3292 99 66.1339 99C78.7228 99 89 88.6697 89 76.0034C89 65.052 81.3142 55.8621 71.0959 53.5756V0.00164973L3.15255 0ZM8.9321 5.82887H65.3216V53.0449C53.1052 53.4759 43.262 63.6081 43.262 76.0017C43.262 77.6295 43.4357 79.2165 43.7582 80.7501H8.93174V76.4965H12.0839V70.6676H8.93174V57.088H12.0839V51.2591H8.93174V37.6795H12.0839V31.8564H8.93174V18.2933H12.0839V12.4644H8.93174L8.9321 5.82887ZM31.4767 12.0387L25.2345 18.402L22.8492 15.5644L18.4339 19.346L24.913 26.9947L35.5935 16.1219L31.4767 12.0387ZM41.8127 16.4629V22.2918H59.4626V16.4629H41.8127ZM31.4767 35.9569L25.2345 42.3202L22.8492 39.4826L18.4339 43.2642L24.913 50.9185L35.5935 40.0457L31.4767 35.9569ZM41.8127 40.3868V46.21H59.4626V40.3868H41.8127ZM66.1329 58.8511C75.6123 58.8511 83.2247 66.4943 83.2247 76.0022C83.2247 85.5099 75.6125 93.1756 66.1329 93.1756C56.6533 93.1756 49.0411 85.5096 49.0411 76.0022C49.0411 66.4944 56.6533 58.8511 66.1329 58.8511ZM31.4758 59.8803L25.2335 66.2436L22.8483 63.406L18.433 67.1877L24.9121 74.8419L35.5925 63.9633L31.4758 59.8803ZM62.445 63.6619V80.3414H74.5296V74.5182H68.2193V63.6619H62.445Z"
        fill="white"
      />
    </svg>
  );
};

export default Plan;
